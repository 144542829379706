<template>
  <v-app-bar app clipped-left clipped-right color="white" dark>
    <v-toolbar-title class="align-center d-flex">
      <span class="logo-icon">
        <v-layout>
          <v-flex xs12>
            <v-img
              src="../../assets/BGFlogo.png"
              width="200px"
              contain
              height="50px"
              class="mt-2"
            />
          </v-flex>
          <!-- <v-flex >
            <h2 style="font-family:poppins;padding-top:8px; color:#3CB043">agogo</h2>
          </v-flex> -->
        </v-layout>
        <!-- -->
      </span>
    </v-toolbar-title>
    <v-app-bar-nav-icon
      style="color:#3CB043"
      @click="
        $vuetify.breakpoint.smAndDown
          ? setSidebarDrawer(!Sidebar_drawer)
          : $emit('input', !value)
      "
      class="hidden-sm-and-down"
    ></v-app-bar-nav-icon>
    <v-app-bar-nav-icon
      style="color:#3CB043"
      class="d-block d-md-none"
      @click="
        $vuetify.breakpoint.smAndDown
          ? setSidebarDrawer(!Sidebar_drawer)
          : $emit('input', !value)
      "
    />
    <v-spacer />
    <v-flex>
      <h2 style=" font-family: poppinsextrabold;;padding-top:8px; color:#B40521">
        MANAGEMENT PORTAL
      </h2>
    </v-flex>
    <v-toolbar-items class=" hidden-sm-and-down">
      <v-row justify="center">
        <v-menu
          :close-on-content-click="true"
          :nudge-bottom="40"
          :nudge-right="50"
          transition="slide-y-transition"
          style="padding-top:40px;"
          bottom
          dark
        >
          <template v-slot:activator="{ on }">
            <v-list-item-avatar tile color="green" v-on="on" style="cursor:pointer; border-radius: 5px;">
              <v-icon>mdi-account</v-icon>
            </v-list-item-avatar>
          </template>
          <v-card max-width="800">
            <!-- <v-card-title >
                <v-layout justify-center v-if="userData">
                  {{userData.firstName}} {{userData.lastName}} ({{userData.agentCode}})
                </v-layout>
              </v-card-title>
              <v-card-subtitle v-if="userData">{{userData.email}}</v-card-subtitle> -->
            <v-layout pa-3>
              <v-flex>
                <!-- <v-layout style="cursor:pointer;">
                  <v-flex>
                    <span style="font-size:12px;color:white"
                      >Account Settings</span
                    >
                  </v-flex>
                  <v-flex pl-3>
                    <v-icon>mdi-settings</v-icon>
                  </v-flex>
                </v-layout> -->
                <!-- <v-divider></v-divider> -->
                <!-- <v-layout @click="appSettings" style="cursor:pointer;">
                  <v-flex>
                    <span style="font-size:12px;color:white"
                      >Change Password</span
                    >
                  </v-flex>
                  <v-flex pl-3>
                    <v-icon>mdi-settings</v-icon>
                  </v-flex>
                </v-layout>
                <v-divider></v-divider> -->
                <v-layout @click="appLogout" style="cursor:pointer;">
                  <v-flex text-left>
                    <span style="font-size:12px;">Sign Out</span>
                  </v-flex>
                  <v-flex text-right>
                    <v-icon style="font-size:14px;">mdi-logout</v-icon>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-menu>
        <v-spacer></v-spacer>
      </v-row>
    </v-toolbar-items>
  </v-app-bar>
</template>
<script>
// Utilities
// import store from "../../store";
import axios from "axios";
import { mapState, mapMutations } from "vuex";
export default {
  name: "Header",

  components: {},

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    userData: null,
    userprofile: [
      { title: "My Profile" },
      { title: "My Balance" },
      { title: "Inbox" },
      { title: "Account Setting" },
      { title: "Logout" },
    ],
    href() {
      return undefined;
    },
  }),

  computed: {
    ...mapState(["Sidebar_drawer"]),
  },
  beforeMount() {
    this.getProfile();
  },
  methods: {
    ...mapMutations({
      setSidebarDrawer: "SET_SIDEBAR_DRAWER",
    }),
    getProfile() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/user/me",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.userData = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    appLogout() {
      this.$store.commit("logoutUser", true);
      // if (store.state.isLoggedIn) {
      //   localStorage.removeItem("token");
      //   localStorage.removeItem("role");
      //   localStorage.removeItem("agentCode");
      //   store.commit("logoutUser");
      //   this.$router.push({ path: "/Login" });
      // }
    },
    appSettings() {
      this.$router.push({ path: "/Changepassword" });
    },
  },
};
</script>

<style>
.router-link {
  text-decoration: none;
}
.text-color {
  background-image: linear-gradient(to right, #fa709a 0%, #fee140 100%);
}
.size {
		z-index: 1;
		position: absolute;
		border: 2px solid black;
		left: 0;
		top: 0;
	}
</style>
