<template>
  <v-navigation-drawer v-model="Sidebar_drawer" :dark="SidebarColor !== 'white'" :color="SidebarColor"
    mobile-break-point="960" clipped :right="$vuetify.rtl" mini-variant-width="70" :expand-on-hover="expandOnHover" app
    id="main-sidebar">
    <v-list dense nav>
      <!---USer Area -->
      <!-- <v-list-item two-line class="px-0">
        <v-list-item-avatar>
          <img src="https://randomuser.me/api/portraits/men/81.jpg" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>Dohn Deo</v-list-item-title>
          <v-list-item-subtitle class="caption">Webdesigner</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item> -->
      <!---USer Area -->
      <!---Sidebar Items -->
      <v-list-item v-for="item in adminitems" :key="item.title" :to="item.to" :active-class="`green white--text`" link>
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title style="font-family: poppinsmedium;">{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- <v-list-group
        :active-class="`black--text`"
        :value="false"
        no-action
        prepend-icon="mdi-cards"
      >
        <template v-slot:activator>
          <v-list-item-title style="font-family: poppinsmedium;">Course</v-list-item-title>
        </template>
        <v-list-item
          v-for="item in Courses"
          :key="item.title"
          :to="item.to"
          :active-class="`green white--text`"
          link
        >
          <v-list-item-title style="font-family: poppinsmedium;">{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list-group> -->
      <!-- <v-list-group
        :active-class="`black--text`"
        :value="false"
        no-action
        prepend-icon="mdi-cog"
      >
        <template v-slot:activator>
          <v-list-item-title style="font-family: poppinsmedium;">Settings</v-list-item-title>
        </template>
        <v-list-item
          v-for="item in settings"
          :key="item.title"
          :to="item.to"
          :active-class="`green white--text`"
          link
        >
          <v-list-item-title style="font-family: poppinsmedium;">{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list-group> -->
      <!-- <v-list-group :value="false" no-action prepend-icon="mdi-information" :active-class="`black--text`">
        <template v-slot:activator>
          <v-list-item-title style="font-family: poppinsmedium;">Static</v-list-item-title>
        </template>
        <v-list-item to="/static/Privacy Policy" :active-class="`green white--text`" link>
          <v-list-item-title style="font-family: poppinsmedium;">Privacy Policy</v-list-item-title>
        </v-list-item>
        <v-list-item to="/static/Shipping Policy" :active-class="`green white--text`" link>
          <v-list-item-title style="font-family: poppinsmedium;">Shipping Policy</v-list-item-title>
        </v-list-item>
        <v-list-item to="/static/Refund Policy" :active-class="`green white--text`" link>
          <v-list-item-title style="font-family: poppinsmedium;">Refund Policy</v-list-item-title>
        </v-list-item>
        <v-list-item to="/static/Terms and Conditions" :active-class="`green white--text`" link>
          <v-list-item-title style="font-family: poppinsmedium;">Terms and Conditions</v-list-item-title>
        </v-list-item>
      </v-list-group> -->
      <!---Sidebar Items -->
      <!-- <v-list-group :active-class="`black--text`" :value="false" no-action prepend-icon="mdi-file-image">
        <template v-slot:activator>
          <v-list-item-title>Add Images</v-list-item-title>
        </template>
        <v-list-item
          v-for="item in images"
          :key="item.title"
          :to="item.to"
          :active-class="`green white--text`"
          link
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list-group> -->

      <!-- <v-list-group
        :active-class="`black--text`"
        :value="false"
        no-action
        prepend-icon="mdi-taxi"
      >
        <template v-slot:activator>
          <v-list-item-title>Auto</v-list-item-title>
        </template>
        <v-list-item
          v-for="item in auto"
          :key="item.title"
          :to="item.to"
          :active-class="`green white--text`"
          link
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-group
        :active-class="`black--text`"
        :value="false"
        no-action
        prepend-icon="mdi-car"
      >
        <template v-slot:activator>
          <v-list-item-title>Taxi</v-list-item-title>
        </template>
        <v-list-item
          v-for="item in cab"
          :key="item.title"
          :to="item.to"
          :active-class="`green white--text`"
          link
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list-group> -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Sidebar",
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    userRole: localStorage.getItem("role"),
    adminitems: [
      {
        title: "Dashboard",
        icon: "mdi-view-dashboard",
        to: "/dashboard",
      },
      {
        title: "User List",
        icon: "mdi-account -circle",
        to: "/userList",
      },
      {
        title: "Home",
        icon: "mdi-home",
        to: "/banner"
      },
      {
        title: "Category",
        icon: "mdi-view-list",
        to: "/category",
      },
      {
        title: "Sub-category",
        icon: "mdi-format-list-bulleted-square",
        to: "/subcategory",
      },
      {
        title: "Course List",
        icon: "mdi-cards",
        to: "/courses",
      },
      {
        title: "About",
        icon: "mdi-information",
        to: "/about",
      },
      {
        title: "Enquiry",
        icon: "mdi-contacts",
        to: "/enquiryList",
      },
      {
        title: "Enroll Users",
        icon: "mdi-account-multiple",
        to: "/enrollUsers",
      },
      {
        title: "Exam List",
        icon: "mdi-playlist-check",
        to: "/examListMain",
      },
      {
        title: "Exam Rules",
        icon: "mdi-information",
        to: "/examRules",
      },
      {
        title: "Purchase",
        icon: "mdi-cart",
        to: "/purchaseList",
      },
      {
        title: "Payment Report",
        icon: "mdi-cart",
        to: "/paymentReport",
      },
    ],
    // Courses: [
    //   { title: "Industry Safety Training", to: "/cartSetting" },
    //   { title: "Film Saftey Training", to: "/defaultSlider" },
    // ],
    // stat: [
    //   {
    //     name: "Privacy Policy",
    //     to: "/static/Privacy Policy",
    //   },
    //   {
    //     name: "Shipping Policy",
    //     to: "/static/Shipping Policy",
    //   },
    //   {
    //     name: "Refund Policy",
    //     to: "/static/Refund Policy",
    //   },
    //   {
    //     name: "Terms and conditions",
    //     to: "/static/Terms and conditions",
    //   },
    // ],
    Home: [
      { title: "Banner", to: "/banner" },
    ],
    // auto: [
    //   { title: "Auto User", to: "/taxiUser" },
    //   { title: "Driver Verification", to: "/taxiUserPending" },
    //   { title: "Driver Pending Updates", to: "/taxiPendingUpdates" },
    //   { title: "Vehicle List", to: "/vehicleList" },
    //   { title: "Vehicle Verification", to: "/vehiclePending" },
    //   { title: "Vehicle Pending Updates", to: "/vehiclePendingUpdates" },
    //   { title: "Panic Pending", to: "/panicPending" },
    //   { title: "Panic Active", to: "/panicActive" },
    //   { title: "Panic List", to: "/panicList" },
    // ],
    //  cab: [
    //   { title: "Taxi User", to: "/driverList" },
    //   { title: "Driver Verification", to: "/driverPending" },
    //   { title: "Driver Pending Updates", to: "/driverPendingUpdates" },
    //   { title: "Vehicle List", to: "/cabVehicleList" },
    //   { title: "Vehicle Verification", to: "/cabVehiclePending" },
    //   { title: "Vehicle Pending Updates", to: "/cabVehiclePendingUpdates" },
    //   { title: "Panic Pending", to: "/cabPanicPending" },
    //   { title: "Panic Active", to: "/cabPanicActive" },
    //   { title: "Panic List", to: "/cabPanicList" },
    // ],
    // images: [{ title: "Category-Slider", to: "/addImage/category" }],
  }),
  computed: {
    ...mapState(["SidebarColor", "SidebarBg"]),
    Sidebar_drawer: {
      get() {
        return this.$store.state.Sidebar_drawer;
      },
      set(val) {
        this.$store.commit("SET_SIDEBAR_DRAWER", val);
      },
    },
  },
  watch: {
    "$vuetify.breakpoint.smAndDown"(val) {
      this.$emit("update:expandOnHover", !val);
    },
  },

  methods: {},
};
</script>
<style lang="scss">
#main-sidebar {
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);

  .v-navigation-drawer__border {
    display: none;
  }

  .v-list {
    padding: 8px 15px;
  }

  .v-list-item {

    &__icon--text,
    &__icon:first-child {
      justify-content: center;
      text-align: center;
      width: 20px;
    }
  }

  .success {
    background-color: #1e88e5 !important;
    border-color: #1e88e5 !important;
  }
}
</style>
